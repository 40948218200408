.img-size {
  border-radius: 10px;
  width: 80%;
}

.pdf-viewer-container {
  width: 100%;
  max-height: 100vh;
}

.pdf-canvas {
  display: block;
  margin: 0 auto;
}

.pdf-footer {
  position: sticky;
  bottom: 0;
  background: white;
  border-top: 1px solid #dee2e6;
}

.PdfViewer_pdf-viewer__vLJMw {
  background: #fff !important;
}

@media screen and (max-width: 991px) {
  .img-size {
    width: 60%;
  }
}

:global {
  #readModal {
    padding: 0 !important;
    
    .modal-content {
      border-radius: 0;
    }

    .pdf-viewer-container {
      height: calc(100vh - 60px) !important; // Adjusting for modal header
    }
  }
}
