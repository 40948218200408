.hero-teacher {
  padding-top: 80px;
  // padding-top: 70px;
  padding-bottom: 70px;
}

.hero-student {
  width: 100%;
  padding-top: 170px;
  // padding-top: 70px;
  padding-bottom: 70px;
}

.hero-parent {
  padding-top: 160px;
  // padding-top: 70px;
  padding-bottom: 70px;
}

@media screen and (max-width: "991px") {
  .desc {
    font-size: 0.9rem !important;
  }
  .title {
    font-size: 1.5rem !important;
  }
  .subtitle {
    font-size: 1rem !important;
  }
  .hero-student {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .hero-teacher {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .hero-parent {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
