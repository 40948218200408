// @import url("https://fonts.googleapis.com/css2?family=Manrope&display=swap");

// Import font style
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;600;800&Neuton:ital,wght@0,200;0,300;0,400;0,700;0,800;1,400&display=swap");

// Primary color
$primary: #e5f8ff;
$blue: #2b4da2;
$orange: #f1883c;
$dark-blue: #405272;

$cards: 4;
$cardHeight: 87vh;
$cardTopPadding: 2em;
$cardMargin: 4vw; 

*,
body {
  font-family: Manrope;
}

.object-fit-cover {
  object-fit: cover;
}

.timeline {
  position: relative;
  max-width: 800px;
  margin: auto;
  padding: 40px 0;
}

.event {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
}

.event:nth-child(even) .event-content {
  justify-content: flex-end;
  text-align: right;
}

.event-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 20px;
  background: white;
  border-radius: 6px;
  width: 45%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.event-content.left {
  margin-left: auto;
  border-left: 4px solid #00bfa5;
  padding-left: 20px;
}

.event-content.right {
  margin-right: auto;
  border-right: 4px solid #00bfa5;
  padding-right: 20px;
}

.dashed {
  border: none;
  height: 2px !important;
  background: #000;
  background: repeating-linear-gradient(90deg,#000,#000 6px,transparent 6px,transparent 12px);
}

 #sidebar-kurikulum {
  .menu-active {
    font-weight: 800 !important;
    border-left: 3px solid $blue;
    color: $blue !important;
  }
 }

.image {
  width: 100%;
  height: auto;
  border-radius: 6px;
}

.card-hover {
  transition: all 0.3s;
}

.card-hover:hover {
  transform: translate(0, -5px);
  cursor: 
  pointer;
}

.blur {
  filter: blur(3px);
}

.image-container {
  position: relative;
  width: 100%;
  padding-top: 150%; /* Menggunakan padding-top untuk membuat aspek rasio 2:3 */
  overflow: hidden;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn-feedback {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%) rotate(270deg);
  transform-origin: right bottom;
  z-index: 10000 !important;
}

.placeholder {
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

// Background
.bg-light-blue {
  background-color: #EEF4FF !important;
}
.bg-soft-orange {
  background-color: #FFF7F4 !important;
}
.bg-soft-blue {
  background-color: $primary !important;
}
.bg-soft-grey {
  background-color: #f5f5f5;
}
.bg-white {
  background-color: #fff !important;
}
.bg-blue {
  background-color: $blue !important;
}
.bg-dark-blue {
  background-color: $dark-blue !important;
}
.bg-pink {
  background-color: #fff0e6;
}
.bg-night {
  background-color: #6686b7 !important;
}

// Text
.text-1rem {
  font-size: 1rem;
}

.text-neuton {
  font-family: 'Neuton' !important;
}
.text-orange {
  color: #FF8A37 !important;
}

.text-blue {
  color: $blue !important;
}

.text-dark-blue {
  color: $dark-blue !important;
}

.card-rounded {
  border-radius: 20px !important;
}

// Button
.btn-white {
  background-color: #fff !important;
}
.btn-primary {
  background-color: $blue !important;
  border-color: $blue !important;
  &:focus {
    box-shadow: none !important;
  }
}
.btn-outline-primary {
  border-color: $blue !important;
  color: $blue !important;
  &:hover {
    background-color: $blue !important;
    color: white !important;
  }
  &:focus {
    box-shadow: none !important;
  }
}
.dropdown-toggle.show {
  background-color: $blue !important;
  color: #fff !important;
}
.btn-outline-white {
  color: #fff !important;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgb(202, 202, 202) !important;
}
.btn-outline-white:hover {
  color: #000 !important;
  background-color: #fff !important;
}
.btn-outline-blue {
  font-weight: 600 !important;
  border: 1px solid $blue !important;
  background-color: rgba(0, 0, 0, 0) !important;
  color: $blue !important;
}
.btn-outline-blue:hover {
  background-color: $blue !important;
  color: white !important;
}
.btn-orange {
  background-color: #f1883c !important;
  color: #fff !important;
}

.btn-outline-orange {
  font-weight: 600 !important;
  border: 1px solid $orange !important;
  background-color: rgba(0, 0, 0, 0) !important;
  color: $orange !important;
}

.btn-outline-orange:hover {
  background-color: $orange !important;
  color: white !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -0.75rem !important;
}

.mt-n4 {
  margin-top: -1rem !important;
}

.m-faq {
  margin-left: 12px;
}

.cursor-pointer {
  cursor: pointer !important;
}

// .tooltip-inner,
// .tooltip-arrow {
//   background-color: $dark-blue !important;
//   color: #fff !important;
// }

#sidebar {
  .nav-link {
    color: #494949;
  }
  .nav-link:hover {
    color: black;
  }
  .nav-link.active {
    background-color: white;
    font-weight: bold;
    color: $blue;
  }
}

#catalog {
  // Tag design
  .tag {
    background: #eee;
    border-radius: 3px 0 0 3px;
    border-bottom: 1px solid white;
    color: #999;
    display: inline-block;
    height: 26px;
    line-height: 26px;
    padding: 0 20px 0 23px;
    position: relative;
    margin: 0 10px 10px 0;
    text-decoration: none;
    -webkit-transition: color .1s;
  }
  .tag-active {
    background: $blue;
    color: #fff;
    &::after {
      background: #fff;
      border-bottom: 13px solid transparent;
      border-left: 10px solid $blue !important;
      border-top: 13px solid transparent;
      content: '';
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .tag::before {
    background: #fff;
    border-radius: 10px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
    content: '';
    height: 6px;
    left: 10px;
    position: absolute;
    width: 6px;
    top: 10px;
  }
  .tag::after {
    background: #fff;
    border-bottom: 13px solid transparent;
    border-left: 10px solid #eee;
    border-top: 13px solid transparent;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
  }
  .tag:hover {
    background-color: $blue;
    color: white;
  }
  .tag:hover::after {
     border-left-color: $blue; 
  }

  .filter-type {
    width: 100% !important;
  }
  @media screen and (min-width: 768px) {
    .filter-type {
      width: 40% !important;
    }
  }
}

#typeCatalogue {
  .card-type {
    border-radius: 10px;
  }
  .active-type-catalogue {
    border: 2px solid orange !important;
    // border: 2px solid red !important;
  }
}


#navbar {
  // background-color: $primary;
  .nav-logo {
    width: 230px;
    height: auto;
  }
  .dropdown-desktop {
    display: none;
  }
  @media screen and (min-width: 992px) {
    .dropdown-mobile {
      display: none;
    }
    .dropdown-desktop {
      display: block;
    }
    .dropdown:hover .dropdown-menu:not(.profile) {
      display: block !important;
    }
  }
}

#login {
  background: url("../../public/assets/image/login/background.png");
  background-color: $primary !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  padding-bottom: 80px !important;
  .active {
    color: red !important;
  }
}

#register {
  background: url("../../public/assets/image/login/background.png");
  background-color: $primary !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  padding-bottom: 80px !important;

  .active {
    color: red !important;
  }
}

#accessBook {
  @media screen and (max-width: 768px) {
    .text-access-book {
      font-size: 0.8rem;
    }
    .line-access {
      width: 93% !important;
    }
  }
}

.fade-enter {
  opacity: 1;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translateX(20px);
  transition: all 0.5s;
  // transition: opacity 250ms ease-out, transform 300ms ease;
}
.fade-exit {
  opacity: 0;
  transform: translateX(20px);
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translateX(20px);
  transition: all 0.5s;
  // transition: opacity 250ms ease-out, transform 300ms ease;
}

#audioPlayer {
  .nav {
    border: 0;
    .active {
      font-weight: 600;
      color: $blue !important;
      border: 0;
    }
    .nav-link {
      border: 0;
    }
  }
}

#Footer {
  .nav-link {
    color: #fff !important;
  }
}

.pdf-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.pdf-header {
  padding: 1rem;
}

.pdf-body {
  flex: 1;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  flex-direction: column;
}

.pdf-canvas {
  max-width: 100%;
  max-height: 100%;
  height: 80vh;
}

.pdf-footer {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

@media screen and (max-width: 768px) {
  .pdf-body {
    overflow: initial;
  }

  .pdf-canvas {
    max-width: 100%;
    max-height: 80vh;
    margin: 1rem;
  }
}

//transition
.pdf-canvas {
  max-width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.slide-left {
  transform: translateX(-100%);
}

.slide-right {
  transform: translateX(100%);
}

#cards {
	list-style: none;
	padding-left: 0;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(var(--cards), var(--cardHeight));
	gap: var(--cardMargin);
	padding-bottom: calc(var(--cards) * var(--cardTopPadding));
	// margin-bottom: var(--cardMargin);

  #card1 {
    --index: 1;
    top: 100px;
  }
  #card2 {
    --index: 2;
    top: 120px;
  }
  #card3 {
    --index: 3;
    top: 140px;
  }
  #card4 {
    --index: 4;
    top: 160px;
  }
  #card5 {
    --index: 5;
    top: 180px;
  }

  .card-program {
    position: sticky;
    border: none;
    padding-top: calc(var(--index) * var(--cardTopPadding));
    margin-bottom: 30px;
    // max-width: 300px;
  }

  .card-body {
    height: var(--cardHeight);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
  }
}

.swiper-button-next,
.swiper-button-prev {
  background-color: white;
  background-color: rgba(255, 255, 255, 0.5);
  top: 30px;
  right:10px;
  padding: 30px;
  color: #FF8A37 !important;
  fill: #FF8A37 !important;
  stroke: #FF8A37 !important;
}

.swiper-button-prev {
  right: 80px !important;
}

.swiper-button-next {
  right: 10px !important;
}