.card {
  transition: 0.2s ease-in;
}
.card:hover {
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
  transform: translate(0, -5px);
}

.img-size {
  border-radius: 10px;
  width: 75%;
}

@media screen and (max-width: 991px) {
  .img-size {
    width: 50%;
    height: 240px;
  }
}
