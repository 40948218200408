.count > span {
    font-size: 2.2rem;
    color: white;
    font-weight: bold;
}

@media screen and (max-width: 700px) {
    .count > span {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .count > span::after {
        content:"\a";
        white-space: pre;
    }
}