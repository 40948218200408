.pdf-viewer {
  width: 100%;
  height: 100%;
  min-height: 500px;
  background-color: #f5f5f5;
  padding: 20px;

  .canvas-container {
    max-width: 100%;
    overflow-x: auto;
    position: relative;
    padding: 0 40px;
    
    canvas {
      max-width: 100%;
      height: auto !important;
    }
  }

  .canvas-wrapper {
    transition: transform 0.5s ease-in-out;
    transform-origin: center center;
    
    &.flipping {
      animation: flipPage 0.5s ease-in-out;
    }
  }

  .zoom-controls {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
}

@keyframes flipPage {
  0% {
    transform: rotateY(0deg);
    opacity: 1;
  }
  50% {
    transform: rotateY(90deg);
    opacity: 0.5;
  }
  100% {
    transform: rotateY(0deg);
    opacity: 1;
  }
}
